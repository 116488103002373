<template>
	<div class="Message bgfff br4 p32">
		<div v-if="curType == 'list'" class="list">
			<!-- 顶部 start -->
			<div class="nav fs16 fw-7 font-33 pb24 pr00 flex">
				<div @click="topChange(1)" class="cursor" style="width: 120px">
					<div v-if="curSign == 1" class="tit-line flex">
						<span class="pr">平台公告</span>
						<span class="line">平台公告</span>
					</div>
					<div v-else>平台公告</div>
				</div>

				<div @click="topChange(3)" class="cursor">
					<div v-if="curSign == 3" class="tit-line flex">
						<span class="pr">系统消息</span>
						<span class="line">系统消息</span>
					</div>
					<div v-else>系统消息</div>
				</div>
			</div>
			<!-- 顶部 end -->

			<div style="min-height: 600px" v-loading="listLoading">
				<div v-if="arrs.length > 0 || listLoading">
					<div
						v-for="(item, index) in arrs"
						:key="index"
						class="list-item ptb32"
					>
						<div v-if="curSign == 1" class="tit flex justify-between">
							<div class="flex align-center fs16 font-33">
								<img class="mr8" src="@/assets/list/item-con-tip.png" />
								平台公告
							</div>
							<div class="fs14 font-99">{{ item.create_time }}</div>
						</div>
						<div v-if="curSign == 3" class="tit flex justify-between">
							<div class="flex align-center fs16 font-33 fw-7">
								<img class="mr8" src="@/assets/list/item-con-tip.png" />
								{{ item.title }}
							</div>
							<div class="fs14 font-99">{{ item.create_time }}</div>
						</div>

						<div v-if="curSign == 1" class="fs16 fw-7 font-33 pt24">
							{{ item.title }}
						</div>

						<div class="line3 fs14 font-66 mt16">
							{{ curSign == 1 ? item.synopsis : item.content }}
						</div>

						<div v-if="curSign == 1" class="text-r mt16">
							<span class="cursor" @click="getDetail(item)">查看详情</span>
						</div>
					</div>
				</div>
				<div
					v-else
					class="mt24 bgfff br4"
					style="overflow: hidden; padding-bottom: 60px"
				>
					<Empty></Empty>
				</div>
			</div>
			<!-- 分页 start -->
			<div class="page-wrap flex justify-end mt32">
				<el-pagination
					:background="true"
					layout="prev, pager, next"
					:total="total"
					:current-page="page"
					:page-size="pageSize"
					@current-change="change"
					hide-on-single-page
				>
				</el-pagination>
			</div>
			<!-- 分页 end -->
		</div>

		<div v-if="curType == 'detail'" class="detail">
			<!-- 面包屑 start -->
			<div class="bread flex align-center font-33 pb24">
				您现在的位置：<el-breadcrumb
					separator="/"
					separator-class="el-icon-arrow-right"
				>
					<el-breadcrumb-item
						@click.stop.prevent.native="
							(curType = 'list'),
								(title = ''),
								(body = ''),
								((add_time = ''), (detailLoading = false))
						"
						class="is-link"
						>系统消息</el-breadcrumb-item
					>
					<el-breadcrumb-item>详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<!-- 面包屑 end -->

			<div v-loading="detailLoading" class="content pb32">
				<div class="fs16 fw-7 font-33">{{ title }}</div>
				<div class="fs14 font-66 mt12">{{ add_time }}</div>
				<div v-html="body" class="pt24"></div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Message",
	data() {
		return {
			curSign: 1, // 公告 1; 消息 3;
			curType: "list", // 当前在那里 list 列表; detail 详情;

			arrs: [],
			total: 0, // 总条数
			pageSize: 15, // 每页条数
			page: 1, // 当前的页码
			listLoading: false,

			detailLoading: false,
			title: "",
			add_time: "",
			body: "",

			scorllTop: 0, // 距离顶部得距离
		};
	},
	watch: {
		curType: {
			handler(newVal) {
				if (newVal == "list") {
					document.documentElement.scrollTop = this.scorllTop;
					document.body.scrollTop = this.scorllTop;
				}
			},
		},
	},
	mounted() {
		window.addEventListener("scroll", this.messageScroll);
	},
	destroyed() {
		window.removeEventListener("scroll", this.messageScroll);
	},
	created() {
		this.getList();
	},
	methods: {
		getDetail(item) {
			if (this.detailLoading) {
				return false;
			}

			this.curType = "detail";

			this.detailLoading = true;
			this.$pageScrollTop();

			this.$API
				.postOperationGetArticleDetail({ id: item.id })
				.then((res) => {
					this.detailLoading = false;
					if (res.code == 1) {
						this.title = res.data.title;
						this.add_time = res.data.add_time;
						this.body = res.data.body;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		// 分页改变时
		change(e) {
			this.page = e;
			this.getList();
		},

		// 数据列表
		getList() {
			if (this.listLoading) {
				return false;
			}

			let { page, pageSize, curSign } = this;

			this.listLoading = true;
			this.$pageScrollTop();

			this.$API
				.getOperationGetSystemMsgList({
					msgtype: curSign,
					page,
					pagesize: pageSize,
					list_rows: pageSize,
					pageSize,
				})
				.then((res) => {
					this.listLoading = false;

					if (res.code == 1) {
						this.total = res.data.total;
						this.arrs = res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		topChange(sign) {
			this.curSign = sign;
			this.change(1);
		},
		messageScroll() {
			if (this.curType == "list") {
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				this.scorllTop = scrollTop;
			}
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.bread {
		.is-link {
			span {
				font-weight: 700;
				text-decoration: none;
				transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
				color: #303133;
				cursor: pointer;

				&:hover {
					color: #409eff;
				}
			}
		}
	}
}

.Message {
	@extend .w100;
	@extend .h100;

	.nav {
		border-bottom: 1px dashed #eeeeee;
	}

	.list-item {
		border-bottom: 1px solid #eeeeee;

		.tit {
			img {
				width: 18px;
				height: 11px;
			}
		}

		.cursor {
			font-size: 14px;
			color: #4177dd;
		}
	}

	.detail {
		.content {
			min-height: 600px;
		}
	}
}
</style>
